import { pageLayout } from '~/utils/layout'
import { PageContent } from './PageContent'
import { BackgroundWrapper } from './background'

export interface PageLayoutWithoutHeaderProps {
  children: React.ReactNode
  background?: React.ReactElement
}

export const PageLayoutWithoutHeader = ({
  children,
  background,
}: PageLayoutWithoutHeaderProps) => {
  return (
    <div className={pageLayout}>
      {background && <BackgroundWrapper>{background}</BackgroundWrapper>}
      <PageContent>{children}</PageContent>
    </div>
  )
}
